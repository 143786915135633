import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

export const getArticles = createAsyncThunk(
  "appNews/getArticles",
  async (params) => {
    const response = await axios.get("/v1/Article/GetAll", {
      params,
    });
    return {
      params,
      data: response.data.data.items,
      total: response.data.data.totalCount,
    };
  }
);

export const getArticlesForDropdown = createAsyncThunk(
  "appNews/getArticlesForDropdown",
  async (params) => {
    const response = await axios.get("/v1/Article/GetForDropdown", {
      params,
    });
    return {
      params,
      data: response.data.data,
      total: response.data.data.totalCount,
    };
  }
);

export const getArticle = createAsyncThunk("appNews/getArticle", async (id) => {
  if (id === 0 || isNaN(id)) return;

  const response = await axios.get(`/v1/article/${id}`);

  return {
    data: response.data.data,
  };
});

export const createArticle = createAsyncThunk(
  "appNews/createArticle",
  async (article, { dispatch, rejectWithValue }) => {
    try {
      await axios.post("/v1/article", article, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      await dispatch(getArticles());
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateArticle = createAsyncThunk(
  "appNews/updateArticle",
  async (article, { dispatch, rejectWithValue }) => {
    try {
      await axios.put("/v1/article/Update", article, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      await dispatch(getArticles());
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCategories = createAsyncThunk(
  "appNews/getCategories",
  async (params) => {
    const response = await axios.get(
      "/v1/ArticleCategory/GetAll?MaxResultCount=10000",
      {
        params,
      }
    );
    return {
      params,
      data: response.data.data.items,
      total: response.data.data.totalCount,
    };
  }
);

export const getCategoryDetails = createAsyncThunk(
  "appNews/getCategory",
  async (categoryId) => {
    if (categoryId === 0) return;
    const response = await axios.get(`/v1/ArticleCategory/${categoryId}`);

    return response;
  }
);

export const updateCategory = createAsyncThunk(
  "appNews/UpdateCategory",
  async (category, { dispatch, rejectWithValue }) => {
    try {
      await axios.put("/v1/ArticleCategory/Update", category, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      //TODO: maybe change this later to a normal reducer
      //to update the item in the structure without fetching all categories again
      await dispatch(getCategories());
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createCategory = createAsyncThunk(
  "appNews/CreateCategory",
  async (category, { dispatch, rejectWithValue }) => {
    try {
      await axios.post("/v1/ArticleCategory", category, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      await dispatch(getCategories());
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "appNews/DeleteCategory",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      await axios.delete(`/v1/ArticleCategory/${id}`);

      await dispatch(getCategories());
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCategoryParent = createAsyncThunk(
  "appNews/UpdateCategoryParent",
  async (data, { dispatch, rejectWithValue }) => {
    const categoryToMove = await axios.get(`/v1/ArticleCategory/${data.id}`);
    const updateObject = new FormData();
    updateObject.append("id", categoryToMove.data.data.id);
    updateObject.append("parentCategoryId", data.parentCategoryId);
    updateObject.append("name", categoryToMove.data.data.name);

    dispatch(updateCategory(updateObject));
  }
);

export const getArticleCategoryDropdown = createAsyncThunk(
  "appNews/getArticleCategoryDropdown",
  async (id, { dispatch, rejectWithValue }) => {
    const response = await axios.get("/v1/ArticleCategory/GetDropdown");
    return response.data.data;
  }
);

export const deleteArticle = createAsyncThunk(
  "appNews/deleteArticle",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      await axios.delete(`/v1/Article/${id}`);

      await dispatch(getArticles());
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

function makeTree(nodes, parentCategoryId) {
  return nodes
    .filter((node) => node.parentCategoryId === parentCategoryId)
    .reduce(
      (tree, node) => [
        ...tree,
        {
          key: `${node.id}-${node.parentCategoryId}`,
          title:  `${node.name}${node.sorting ? ` (${node.sorting})` : ''}`,
          isLeaf: false,
          children: makeTree(nodes, node.id),
        },
      ],
      []
    );
}

const getNodeIds = (nodes) => {
  let ids = [];

  nodes?.forEach(({ key, children }) => {
    ids = [...ids, key, ...getNodeIds(children)];
  });

  return ids;
};

export const appPftSlice = createSlice({
  name: "appNews",
  initialState: {
    articles: [],
    loadingArticle: false,
    isSavingArticle: false,
    selectedArticle: {},
    totalArticles: 1,
    articlesDropdown: [],
    categoriesDropdown: [],
    categories: [],
    categoryKeyNodes: [],
    totalCategories: 1,
    selectedCategory: {},
    isLoadingSelectedCategory: false,
    isSavingCategory: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateArticle.pending, (state) => {
        state.isSavingArticle = true;
      })
      .addCase(updateArticle.fulfilled, (state) => {
        state.isSavingArticle = false;
      })
      .addCase(updateArticle.rejected, (state) => {
        state.isSavingArticle = false;
      })
      .addCase(createArticle.pending, (state) => {
        state.isSavingArticle = true;
      })
      .addCase(createArticle.fulfilled, (state) => {
        state.isSavingArticle = false;
      })
      .addCase(createArticle.rejected, (state) => {
        state.isSavingArticle = false;
      })
      .addCase(getArticle.pending, (state) => {
        state.loadingArticle = true;
      })
      .addCase(getArticle.fulfilled, (state, action) => {
        state.selectedArticle = action.payload?.data;
        state.loadingArticle = false;
      })
      .addCase(getArticles.fulfilled, (state, action) => {
        state.articles = action.payload.data;
        state.totalArticles = action.payload.total;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        const tree = makeTree(action.payload.data, null);
        state.categories = tree;
        state.totalCategories = action.payload.total;
        state.categoryKeyNodes = getNodeIds(tree);
      })
      .addCase(updateCategory.pending, (state) => {
        state.isSavingCategory = true;
      })
      .addCase(updateCategory.fulfilled, (state) => {
        state.isSavingCategory = false;
      })
      .addCase(updateCategory.rejected, (state) => {
        state.isSavingCategory = false;
      })
      .addCase(getCategoryDetails.fulfilled, (state, action) => {
        state.isLoadingSelectedCategory = false;

        state.selectedCategory = action.payload?.data?.data;
      })
      .addCase(getCategoryDetails.pending, (state) => {
        state.isLoadingSelectedCategory = true;
      })
      .addCase(getCategoryDetails.rejected, (state) => {
        state.isLoadingSelectedCategory = false;
      })
      .addCase(getArticleCategoryDropdown.fulfilled, (state, action) => {
        const categoriesDropdown = [];

        action.payload.forEach((element) => {
          categoriesDropdown.push({
            value: element.id,
            label: element.name,
          });
        });

        state.categoriesDropdown = categoriesDropdown;
      })
      .addCase(getArticlesForDropdown.fulfilled, (state, action) => {
        const aticlesDropdown = [];


        action.payload.data.forEach((element) => {
          aticlesDropdown.push({
            value: element.value,
            label: element.label,
          });
        });

        state.articlesDropdown = aticlesDropdown;
      })
  },
});

export default appPftSlice.reducer;
