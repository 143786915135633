import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

export const getData = createAsyncThunk("appUsers/getData", async (params) => {
  const response = await axios.get("/services/app/User/GetAll", { params });
  return response.data.data;
});

export const addUser = createAsyncThunk(
  "appUsers/addUser",
  async (user, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.post("/services/app/User/Create", user);
      await dispatch(getData(getState().users.params));
    } catch (err) {
      //Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "appUsers/updateUser",
  async (user, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.put("/services/app/User/Update", user);
      await dispatch(getData(getState().users.params));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUser = createAsyncThunk("appUsers/getUser", async (id) => {
  if (id === 0) return null;
  const response = await axios.get(`/services/app/User/Get?id=${id}`);
  return response.data;
});

export const deleteUser = createAsyncThunk(
  "appUsers/deleteUser",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.delete(`/services/app/User/Delete?id=${id}`);
      await dispatch(getData(getState().users.params));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const appUsersSlice = createSlice({
  name: "appUsers",
  initialState: {
    data: [],
    fetchingData: false,
    total: 1,
    params: {},
    error: null,
    selectedUser: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.items;
        state.totalCount = action.payload.totalCount;
        state.params = action.meta.arg;
        state.fetchingData = false;
      })
      .addCase(getData.pending, (state, action) => {
        state.fetchingData = true;
      })
      .addCase(getData.rejected, (state) => {
        state.fetchingData = false;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
      });
  },
});

export default appUsersSlice.reducer;
