import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";

import { apiGenerator } from "./api/apiGenerator";
import * as rootReducerNew from "./rootReducerNew";

export const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: persistReducer(
      rootReducerNew.default.rootConfig,
      rootReducerNew.default.rootReducer
    ),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat([apiGenerator.middleware]),
    ...options,
  });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

const store = createStore();
const persistor = persistStore(store);
const useAppDispatch = () => useDispatch<AppDispatch>();
const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export { useAppDispatch, useTypedSelector, store, persistor };
