// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";
import axios from "axios";

const config = useJwt.jwtConfig;

const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const sendTwoFactorCode = createAsyncThunk(
  "authentication/sendTwoFactorCode",
  async (input, { rejectWithValue }) => {
    try {
      await axios.post(
        "/TokenAuth/SendTwoFactorAuthCode/send-two-factor-code",
        input
      );
    } catch (err) {
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendPasswordResetEmail = createAsyncThunk(
  "authentication/sendPasswordResetEmail",
  async (input, { rejectWithValue }) => {
    try {
      await axios.post("/v1/Account/SendPasswordResetCode", input);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "authentication/resetPassword",
  async (input, { rejectWithValue }) => {
    try {
      await axios.post("/v1/Account/ResetPassword", input);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload;
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName];
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName];
      localStorage.setItem("userData", JSON.stringify(action.payload));
      localStorage.setItem(
        config.storageTokenKeyName,
        action.payload.accessToken
      );

      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        action.payload.refreshToken
      );
      localStorage.setItem(
        config.twoFactorVerificationCodeName,
        action.payload.twoFactorRememberClientToken
      );
    },
    handleLogout: (state) => {
      state.userData = {};
      state[config.storageTokenKeyName] = null;
      state[config.storageRefreshTokenKeyName] = null;
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem(config.storageTokenKeyName);
      localStorage.removeItem(config.storageRefreshTokenKeyName);
      // localStorage.removeItem(config.twoFactorVerificationCodeName);
    },
  },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
