import mock from "../mock";

// ** Utils
import { paginateArray } from "../utils";

const data = {
  users: [
    {
      id: 1,
      fullName: "Galen Slixby",
      status: "Stabiel",
      medication: "Medicatie...",
      longFunctionPercentage: "80%",
      newChatCount: 5,
      newLungFunctionCount: 1,
      avatar: "",
    },
    {
      id: 2,
      fullName: "Tijana Antonisse",
      status: "Onstabiel",
      medication: "Medicatie...",
      longFunctionPercentage: "60%",
      newChatCount: 2,
      newLungFunctionCount: 0,
      avatar: require("@src/assets/images/avatars/10.png"),
    },
    {
      id: 3,
      fullName: "Jelte Heus",
      status: "Onbekend",
      medication: "Medicatie...",
      longFunctionPercentage: "90%",
      newChatCount: 0,
      newLungFunctionCount: 0,
      avatar: require("@src/assets/images/avatars/9.png"),
    },
  ],
};

// GET ALL DATA
mock.onGet("/api/users/list/all-data").reply(200, data.users);

// POST: Add new user
mock.onPost("/apps/users/add-user").reply((config) => {
  // Get event from post data
  const user = JSON.parse(config.data);
  const highestValue = data.users.reduce((a, b) => (a.id > b.id ? a : b)).id;

  user.id = highestValue + 1;

  data.users.push(user);

  return [201, { user }];
});

// GET Updated DATA
mock.onGet("/api/users/list/data").reply((config) => {
  const {
    q = "",
    page = 1,
    perPage = 10,
    sort = "asc",
    status = null,
    sortColumn = "fullName",
  } = config;

  /* eslint-disable  */
  const queryLowered = q.toLowerCase();

  const dataAsc = data.users.sort((a, b) =>
    a[sortColumn] < b[sortColumn] ? -1 : 1
  );

  const dataToFilter = sort === "asc" ? dataAsc : dataAsc.reverse();

  const filteredData = dataToFilter.filter(
    (user) =>
      user.fullName.toLowerCase().includes(queryLowered) &&
      user.status.toLowerCase() === (status.toLowerCase() || user.status)
  );
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      users: paginateArray(filteredData, perPage, page),
    },
  ];
});

// GET USER
mock.onGet("/api/users/user").reply((config) => {
  const { id } = config;
  const user = data.users.find((i) => i.id === id);
  return [200, { user }];
});

// DELETE: Deletes User
mock.onDelete("/apps/users/delete").reply((config) => {
  // Get user id from URL
  let userId = config.id;

  // Convert Id to number
  userId = Number(userId);

  const userIndex = data.users.findIndex((t) => t.id === userId);
  data.users.splice(userIndex, 1);

  return [200];
});
