// ** Core JWT Import
import useJwt from "@src/@core/auth/jwt/useJwt";

const { jwt } = useJwt({
  loginEndpoint: "/TokenAuth/Authenticate",
  registerEndpoint: "/jwt/register",
  refreshEndpoint: "/TokenAuth/RefreshToken",
  logoutEndpoint: "/jwt/logout",

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",
  twoFactorVerificationCodeName: "twoFactorVerificationCode",
});

export default jwt;
