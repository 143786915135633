// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

export const getData = createAsyncThunk("AppTodos/getData", async (params) => {
  const response = await axios.get("/v1/ToDo/GetAll", {
    params,
  });
  return {
    params,
    data: response.data.data.items,
    total: response.data.data.totalCount,
  };
});

export const deleteTodo = createAsyncThunk(
  "appTodos/deleteTodo",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.delete(`/v1/ToDo/${id}`);
      await dispatch(getData(getState().todos.params));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTodo = createAsyncThunk("appTodos/getTodo", async (id) => {
  if (id === 0) return null;
  const response = await axios.get(`/v1/todo/${id}`);
  return response.data.data;
});

export const addTodo = createAsyncThunk(
  "appTodos/addTodo",
  async (todo, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.post("/v1/ToDo", todo);
      // await dispatch(getData(getState().todos.params));
    } catch (err) {
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateTodo = createAsyncThunk(
  "appTodos/updateTodo",
  async (todo, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.put("/v1/todo/Update", todo);
      await dispatch(getData(getState().todos.params));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const appTodosSlice = createSlice({
  name: "AppTodos",
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedTodo: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.total;
      })
      .addCase(getTodo.fulfilled, (state, action) => {
        state.selectedTodo = action.payload;
      });
  },
});

export default appTodosSlice.reducer;
