// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

export const getData = createAsyncThunk(
  "AstmaActionPlanMedications/getData",
  async (params) => {
    const response = await axios.get("/v1/Medication/GetAll", {
      params,
    });
    return {
      params,
      data: response.data.data.items,
      total: response.data.data.totalCount,
    };
  }
);

export const getDetails = createAsyncThunk(
  "AstmaActionPlanMedications/getDetails",
  async (id) => {
    if (id === 0) return null;
    const response = await axios.get(`/v1/Medication/${id}`);
    return response.data.data;
  }
);

export const deleteMedication = createAsyncThunk(
  "AstmaActionPlanMedications/deleteMedication",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.delete(`/v1/Medication/${id}`);
      await dispatch(getData(getState().medications.params));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createMedication = createAsyncThunk(
  "AstmaActionPlanMedications/createMedication",
  async (medication, { dispatch, rejectWithValue }) => {
    try {
      await axios.post("/v1/Medication", medication, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      await dispatch(getData());
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateMedication = createAsyncThunk(
  "AstmaActionPlanMedications/updateMedication",
  async (medication, { dispatch, rejectWithValue }) => {
    try {
      await axios.put("/v1/Medication", medication, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      await dispatch(getData());
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const astmaActionPlanMedicationSlice = createSlice({
  name: "AstmaActionPlanMedication",
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedMedication: null,
    isSavingMedication: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateMedication.pending, (state) => {
        state.isSavingMedication = true;
      })
      .addCase(updateMedication.fulfilled, (state) => {
        state.isSavingMedication = false;
      })
      .addCase(updateMedication.rejected, (state) => {
        state.isSavingMedication = false;
      })
      .addCase(createMedication.pending, (state) => {
        state.isSavingMedication = true;
      })
      .addCase(createMedication.fulfilled, (state) => {
        state.isSavingMedication = false;
      })
      .addCase(createMedication.rejected, (state) => {
        state.isSavingMedication = false;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.total;
      })
      .addCase(getDetails.fulfilled, (state, action) => {
        state.selectedMedication = action.payload;
      });
  },
});

export default astmaActionPlanMedicationSlice.reducer;
