import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import patients from "../views/apps/patient/store";
import users from "../views/administration/users/store";
import pfts from "../views/apps/pft/store";
import todos from "../views/apps/todo/store";
import news from "../views/apps/news/store";
import profile from "../views/account-settings/store";
import medications from "../views/apps/medication/store";

import { combineReducers } from "redux";

import storage from "redux-persist/lib/storage";
import { apiGenerator } from "./api/apiGenerator";

const rootConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  blacklist: ["api"],
};

const rootReducer = combineReducers({
  [apiGenerator.reducerPath]: apiGenerator.reducer,
  auth,
  navbar,
  layout,
  patients,
  users,
  pfts,
  todos,
  news,
  profile,
  medications,
});

export default { rootReducer, rootConfig };
