import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

export const getData = createAsyncThunk("appPfts/getData", async (params) => {
  const response = await axios.get("/v1/PFT/GetAll", {
    params,
  });
  return {
    params,
    data: response.data.data.items,
    total: response.data.data.totalCount,
  };
});

export const getUnreviewedPftCount = createAsyncThunk(
  "appPfts/getUnreviewedPftCount",
  async () => {
    const response = await axios.get("/v1/PFT/GetUnreviewedCount");

    return {
      count: response.data.data,
    };
  }
);

export const downloadPftImage = createAsyncThunk(
  "appPfts/downloadPftImage",
  async (name, { dispatch, getState, rejectWithValue }) => {
    try {
      const result = await axios.get(`/v1/PFT/img/${name}`, {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
        timeout: 30000,
      });

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const appPftSlice = createSlice({
  name: "appPatients",
  initialState: {
    data: [],
    total: 1,
    params: {},
    unreviewedPftCount: 0,
    selectedStatusFilterId: null,
    selectedstatusFilterLabel: null,
  },
  reducers: {
    handleStatusSelection: (state, action) => {
      state.selectedStatusFilterId = action.payload.value;
      state.selectedstatusFilterLabel = action.payload.label;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.total;
      })
      .addCase(getUnreviewedPftCount.fulfilled, (state, action) => {
        state.unreviewedPftCount = action.payload.count;
      });
  },
});

export const { handleStatusSelection } = appPftSlice.actions;

export default appPftSlice.reducer;
