import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

export const updatePassword = createAsyncThunk(
  "profile/updatePassword",
  async (password, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.post("/v1/profile/ChangePassword", password);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProfileData = createAsyncThunk(
  "profile/getProfile",
  async () => {
    const response = await axios.get("/v1/Profile");
    return response.data.data;
  }
);

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (profile, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.post("/v1/profile/Update", profile);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    updatingPassword: false,
    profileData: {},
    loadingProfileData: false,
    savingProfileData: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updatePassword.pending, (state, action) => {
        state.updatingPassword = true;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.updatingPassword = false;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.updatingPassword = false;
      })
      .addCase(getProfileData.pending, (state) => {
        state.loadingProfileData = true;
      })
      .addCase(getProfileData.fulfilled, (state, action) => {
        state.loadingProfileData = false;
        state.profileData = action.payload;
      })
      .addCase(getProfileData.rejected, (state) => {
        state.loadingProfileData = false;
      })
      .addCase(updateProfile.pending, (state) => {
        state.savingProfileData = true;
      })
      .addCase(updateProfile.fulfilled, (state) => {
        state.savingProfileData = false;
      })
      .addCase(updateProfile.rejected, (state) => {
        state.savingProfileData = false;
      });
  },
});

export default profileSlice.reducer;
