import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import Message from "../../utility/notifications/Message";
import { handleLogout } from "../authentication";

const baseQueryWithAuth = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: async (headers) => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    headers.set("Accept", "application/json");
    headers.set("Cache-Control", "no-cache");
    headers.set("Pragma", "no-cache");
    headers.set("Expires", "0");

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQueryWithAuth(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshToken = localStorage.getItem("refreshToken");
    const refreshResult = await baseQueryWithAuth(
      {
        url: `/Auth/RefreshToken?refreshToken=${refreshToken}`,
        method: "POST",
      },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      const refeshTokenResult = refreshResult.data as any;

      // store the new token
      localStorage.setItem("accessToken", refeshTokenResult.data.accessToken);
      localStorage.setItem("refreshToken", refeshTokenResult.data.refreshToken);

      // retry the initial query
      result = await baseQueryWithAuth(args, api, extraOptions);
    } else {
      api.dispatch(handleLogout());
    }
  }

  if (result.error?.status === 405) {
    Message("Something went wrong, please try again later.");
  }

  if (
    result.error &&
    (result.error.status === 500 ||
      result.error.status === 400 ||
      result.error.status === 403)
  ) {
    const data = result?.error?.data as any;
    if (data?.exception) {
      Message(data?.exception, data?.messages?.join(", "), "error");
    }
  }

  return result;
};

export const apiGenerator = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});

export const {} = apiGenerator;
